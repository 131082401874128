import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default ({
	state: {
		accessToken: localStorage.getItem('accessToken'),
	},
	mutations: {
		changeAccessToken(state, token) {
			state.accessToken = token
		}
	},
	actions: {
		setAccessToken({
			commit
		}, token) {
			commit('changeAccessToken', token)
		}
	},
	getters: {
		getAccessToken(state) {
			return state.accessToken
		},
		getBearerToken(state) {
			return {
                headers: {
                    Authorization: `Bearer ${state.accessToken}`
                },
            }
		}
	},
});
