//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(["getBearerToken"])
    },
  created() {
    this.axios
      .post("/api/profile/user-email-confirm/token", {
        token: this.$route.params.token,
        new_email: localStorage.newEmail
      },
      this.getBearerToken
      )
      .then((res) => {
        console.log(res);
        if (res.message == "Sms code sent for confirmation") {
          this.$router.push({ name: "confirm-phone" });
        } else {
            this.$router.push({ name: "login" });
        }
      });
  },
};
