//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "QuoteSection",
  props: {},
  data() {
    return {};
  },
  computed: {
    type() {
      switch (this.propsData.type) {
        case "right_with_image":
          return "2";
        case "right_with_image_icon":
          return "2";
        case "right_without_image":
          return "2";
        case "center":
          return "1";
        default:
          return "1";
      }
    },
    image() {
      if (this.propsData.image) {
        switch (this.propsData.type) {
          case "right_with_image":
            return true;
          case "right_with_image_icon":
            return true;
          case "right_without_image":
            return false;
          case "center":
            return true;
          default:
            return false;
        }
      } else return false;
    },
  },
};
