//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    messages: {
        type: Object,
        default: () => {}
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
      errorText() {
          if ( !this.inputData.email ) {
              return this.messages.email
          }
          if ( !this.inputData.required ) {
              return this.messages.required
          }
          if ( !this.inputData.min ) {
              return this.messages.min
          }
          if ( !this.inputData.max ) {
              return this.messages.max
          }
          return null
      }
  },
};
