//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "stages",
  props: {},
  computed: {
    textSize() {
      return this.propsData.font_size;
    },
  },
};
