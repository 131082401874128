//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
export default {
  data() {
    return {
      email: null,
      password: null,
      errors: null
    };
  },
  methods: {
    ...mapActions(["setAccessToken"]),
    login() {
      this.axios
        .post("/api/auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
        this.setAccessToken(res.data.data.access_token)
          localStorage.setItem("accessToken", res.data.data.access_token);
          setTimeout( () => {
              this.$router.push({name: 'account'})
          }, 2000)
        }).catch((res) => {
            this.errors = res.response.data.data.errors
        })
    },
  },
};
