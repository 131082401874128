//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import formText from "../formWidget/formText.vue";
import formInput from "../formWidget/formInput.vue";
import formTitle from "../formWidget/formTitle.vue";
import FormEditor from "../formWidget/formEditor.vue";
import FormCheckbox from "../formWidget/formCheckbox.vue";
export default {
  components: { formInput, formText, formTitle, FormEditor, FormCheckbox },
  name: "FormModal",
  data() {
    return {
      InputValues: {},
      showError: false,
      success: false,
    };
  },
  computed: {
    ...mapGetters(["getLogo", "getMenuData", "getFormId"]),
  },
  validations() {
    const temp = {};
    temp.InputValues = {};
    this.getMenuData.forEach((el) => {
      temp.InputValues[el.name] = {};
      !(
        //   }
        this.getMenuData.forEach((el) => {
          if (el.rules) {
            let rules = {};
            if (el.rules.required) {
              rules = { ...rules, ...{ required } };
            }
            if (el.rules.email) {
              rules = { ...rules, ...{ email } };
            }
            if (el.rules.min) {
              rules = { ...rules, ...{ minLength: minLength(el.rules.min) } };
            }
            if (el.rules.max) {
              rules = { ...rules, ...{ maxLength: maxLength(el.rules.max) } };
            }
            temp.InputValues[el.name] = {
              ...rules,
            };
          }
        })
      );
    });

    return temp;
  },
  methods: {
    ...mapActions(["setMenuState"]),
    changeChekbox(name, data) {
      this[name] = data;
    },
    goHome(){
        this.setMenuState(false);
    },
    close() {
      this.setMenuState(false);
    },
    sendForm() {
      const option = Object.assign(this.InputValues, {
        form_id: this.getFormId,
      });
      this.showError = true;
      if (!this.$v.$invalid) {
        this.axios.post("/api/request/send", option).then(() => {
          this.success = true;
        });
      }
    },
  },
  mounted() {
    if (this.getMenuData) {
      this.getMenuData.forEach((el) => {
        this.$set(this.InputValues, el.name, "");
      });
    }
  },
};
