//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppLink from "../common/AppLink.vue";
// import BlogArticleLink from "../common/BlogArticleLink.vue";
// import BlogArticlesGrid from "../common/BlogArticlesGrid.vue";
import StyledLink from "../common/StyledLink.vue";
export default {
  // BlogArticlesGrid, BlogArticleLink
  components: { StyledLink, AppLink },
  name: "seeAlso",
};
