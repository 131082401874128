//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppLink from "../common/AppLink.vue";
export default {
  components: { AppLink },
  mounted() {
    this.slideToggle(".accordion-trigger", true);
  },
};
