//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "CanvasBlur",
	data() {
		return {
			cnv: null,
			ctx: null,
			w: null,
			h: null,
			radius: null,
			circles: [],
			speed: 0.003,
		};
	},
	mounted() {
		this.cnv = this.$refs.canvas;
		this.ctx = this.cnv.getContext("2d");
		this.setSize();
		window.addEventListener("resize", this.setSize);
		this.createCircle();
		this.drawAnimation();
	},
	methods: {
		setSize() {
			this.w = this.cnv.width = innerWidth;
			this.h = this.cnv.height = innerHeight;

			const v = innerWidth > innerHeight ? innerWidth : innerHeight;
			this.radius = (innerWidth * 1200) / v;
		},
		createCircle() {
			this.circles.push(
				{
					x: Math.random() * this.w,
					y: Math.random() * this.h,
					angle: Math.random() * 2 * Math.PI,
					firstColor: "#a0aed4",
					// firstColor: "red",
					secondColor: "rgba(160, 174, 212, 0)",
				},
				{
					x: Math.random() * this.w,
					y: Math.random() * this.h,
					angle: Math.random() * 2 * Math.PI,
					firstColor: "#f0ebf2",
					// firstColor: "blue",
					secondColor: "rgba(160, 174, 212, 0)",
				}
			);
		},
		draw() {
			this.ctx.fillStyle = "#c9d6f9";
			this.ctx.fillRect(0, 0, this.w, this.h);

			this.circles.forEach((circle) => {
				circle.angle += this.speed;
				const x = circle.x + Math.cos(circle.angle) * 200;
				const y = circle.y + Math.sin(circle.angle) * 200;
				const gradient = this.ctx.createRadialGradient(
					x,
					y,
					0,
					x,
					y,
					this.radius
				);
				gradient.addColorStop(0, circle.firstColor);
				gradient.addColorStop(.3, circle.firstColor);
				gradient.addColorStop(1, circle.secondColor);
				this.ctx.globalCompositeOperation = "overlay";
				this.ctx.fillStyle = gradient;
				// this.ctx.fillStyle = circle.firstColor;
				this.ctx.beginPath();
				this.ctx.arc(x, y, this.radius, 0, 2 * Math.PI);
				this.ctx.fill();
			});
		},
		clearCanvas() {
			this.ctx.clearRect(0, 0, this.w, this.h);
		},
		drawAnimation() {
			this.clearCanvas();
			this.draw();
			window.requestAnimationFrame(() => this.drawAnimation());
		},
	},
};
