import Vue from "vue";
import VueRouter from "vue-router";
import defaultLayout from "@/layouts/Default.vue";
import Landing from "@/views/Landing.vue";
import Article from "@/views/Article.vue";
import BlogPage from "@/views/BlogPage.vue";
import test from "@/views/PersonalAccount.vue";
import register from "@/components/account/Register.vue";
import emailConfirm from "@/components/account/UserEmailConfirm.vue";
import profileEmailConfirm from "@/components/account/ProfileUserEmailConfirm.vue";
import login from "@/components/account/Login.vue";
import passwordReset from "@/components/account/PasswordReset.vue";
import passwordResetToken from "@/components/account/PasswordResetToken.vue";
import t from "@/components/CanvasBlur.vue";

Vue.use(VueRouter);

const routes = [{
		path: "/t",
		name: "t",
		component: t,
	},
	{
		path: "",
		component: defaultLayout,
		children: [{
				path: "/:locale(ru|uk|en)?",
				name: "home",
				component: Landing,
			},
			// account
			{
				path: "/:locale(ru|uk|en)?/account",
				name: "account",
				component: test,
			},
			{
				path: "/:locale(ru|uk|en)?/register-user",
				name: "register",
				component: register,
			},
			{
				path: "/:locale(ru|uk|en)?/login-user",
				name: "login",
				component: login,
			},

			{
				path: "/:locale(ru|uk|en)?/profile/user-email-confirm/token/:token",
				name: "profile-user-email-confirm",
				component: profileEmailConfirm,
			},
			{
				path: "/:locale(ru|uk|en)?/user-email-confirm/token/:token",
				name: "user-email-confirm",
				component: emailConfirm,
			},
			{
				path: "/:locale(ru|uk|en)?/password-reset",
				name: "password-reset",
				component: passwordReset,
			},
			{
				path: "/:locale(ru|uk|en)?/password-reset/token/:token",
				name: "password-reset-token",
				component: passwordResetToken,
			},
			{
				path: "/:locale(ru|uk|en)?/confirm-phone",
				name: "confirm-phone",
				component: test,
			},
			// account end
			{
				path: "/:locale(ru|uk|en)?/news",
				name: "news",
				component: BlogPage,
			},
			{
				path: "/:locale(ru|uk|en)?/news/:slug",
				name: "rubric",
				component: BlogPage,
			},
			{
				path: "/:locale(ru|uk|en)?/news/:rubric/:slug",
				name: "article",
				component: Article,
			},
			{
				path: "/:locale(ru|uk|en)?/:slug",
				name: "page",
				component: Landing,
			},
			{
				path: "/:locale(ru|uk|en)?/landing/:slug",
				name: "landing",
				component: Landing,
			},

			{
				path: "/:locale(ru|uk|en)?/404",
				name: "error",
				component: () =>
					import( /* webpackChunkName: "Error" */ "@/views/Error.vue"),
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	// base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition || (to.name == "events" && from.name == "events"))
			return savedPosition;
		return {
			x: 0,
			y: 0,
		};
	},
	routes,
});

export default router;
