import { render, staticRenderFns } from "./PasswordResetToken.vue?vue&type=template&id=4510e38c&scoped=true&"
import script from "./PasswordResetToken.vue?vue&type=script&lang=js&"
export * from "./PasswordResetToken.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4510e38c",
  null
  
)

export default component.exports