import Vue from "vue";
import Vuex from "vuex";
import pa from "./modules/personalAcc.js";
import form from "./modules/form";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    options: {
      contact_center: null,
      main_page_type: null,
      main_page_page_id: null,
      main_page_landing_id: null,
      blog_title: null,
      blog_description: null,
      blog_meta_title: null,
      blog_meta_description: null,
      blog_per_page: null,
      blogtags_title: null,
      blogtags_description: null,
      blogtags_meta_title: null,
      blogtags_meta_description: null,
      blogtags_per_page: null,
      favicon: null,
      default_og_image: null,
      logotype: null,
      blog_subscribe_btn_title: null,
      blog_subscribe_placeholder: null,
      blog_subscribe_block_visible: null,
      maps_api_key: null,

      contacts: null,

      address: null,
      email: null,
      phones: null,
      schedules: null,
      links: null,
    },
    headerMenu: null,
    footerMenu: null,
  },
  mutations: {
    SETOPTIONS(state, payload) {
      state.options.contact_center = payload.contact_center;
      state.options.main_page_type = payload.main_page_type;
      state.options.main_page_page_id = payload.main_page_page_id;
      state.options.main_page_landing_id = payload.main_page_landing_id;
      state.options.blog_title = payload.blog_title;
      state.options.blog_description = payload.blog_description;
      state.options.blog_meta_title = payload.blog_meta_title;
      state.options.blog_meta_description = payload.blog_meta_description;
      state.options.blog_per_page = payload.blog_per_page;
      state.options.blogtags_title = payload.blogtags_title;
      state.options.blogtags_description = payload.blogtags_description;
      state.options.blogtags_meta_title = payload.blogtags_meta_title;
      state.options.blogtags_meta_description =
        payload.blogtags_meta_description;
      state.options.blogtags_per_page = payload.blogtags_per_page;
      state.options.favicon = payload.favicon;
      state.options.default_og_image = payload.default_og_image;
      state.options.logotype = payload.logotype;

      state.options.address = payload.address;
      state.options.email = payload.email;
      state.options.phones = payload.phones;
      state.options.schedules = payload.schedules;
      state.options.links = payload.links;

      state.options.blog_subscribe_btn_title = payload.blog_subscribe_btn_title;
      state.options.blog_subscribe_placeholder = payload.blog_subscribe_placeholder;
      state.options.blog_subscribe_block_visible = payload.blog_subscribe_block_visible;
      state.options.maps_api_key = payload.maps_api_key;

      state.contacts = payload.contacts;
    },
    SETHEADERMENU(state, payload) {
      state.headerMenu = payload;
    },
    SETFOOTERMENU(state, payload) {
      state.footerMenu = payload;
    },
  },
  getters: {
    options(state) {
      return state.options ? state.options : null;
    },
    getLogo(state) {
      return state.options.logotype ? state.options.logotype : null;
    },
    getFooterMenu(state) {
      return state.footerMenu ? state.footerMenu : null;
    },
    getHeaderMenu(state) {
      return state.headerMenu ? state.headerMenu : null;
    },
    getHeaderPhone(state) {
      if (state.options.phones && state.options.phones.length > 0) {
        return state.options.phones[0].number;
      } else return null;
    },
    getFooterPhone(state) {
      if (state.options.phones && state.options.phones.length > 0) {
        return state.options.phones
      } else return null;
    },
    getSocialLinks(state) {
      if (state.contacts && state.contacts.length > 0) {
        state.options.contacts.forEach(item => {
          if (item.is_main) {
            return item.socials
          }
        });
      } else return null;
    },
  },
  actions: {},
  modules: {
	pa,
    form
  },
});
