//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // register
      name: null,
      email: null,
      newEmail: null,
      password: null,
      confirmPassword: null,
      phone: null,
      //   change pass
      changePassToken: null,
      //   userData
      userData: null,
      code: null,
      error: null
    };
  },
  computed: {
    ...mapGetters(["getBearerToken"]),
  },
  methods: {
    logout() {
      this.axios
        .post("/api/auth/logout", {}, this.getBearerToken)
        .then(() => {
          localStorage.setItem('accessToken', '')
          this.$router.push({name: "login"})
        });
    },

    getUserData() {
      this.axios
        .post("/api/auth/user-profile", {}, this.getBearerToken)
        .then((res) => {
          this.userData = res.data.data;
        });
    },
    editProfileInfo() {
      const option = {};
      console.log(this.newEmail);
      console.log(localStorage.newEmail);
      if (this.phone) {
        option.phone = this.phone;
      }
      if (this.newEmail) {
        option.email = localStorage.newEmail;
      }
      if (this.name) {
        option.name = this.name;
      }
      console.log(option);
      this.axios.post("/api/profile/edit", option, this.getBearerToken).then( (res) => {
          console.log(res.data.data);
          if ( res.data.data.errors ) {
              this.error = res.data.data.errors
          } else {
              this.error = []
          }
          this.getUserData();
      })
    },

  },
  watch: {
    newEmail(newValue) {
        localStorage.setItem('newEmail', newValue)
    }
  },
  created() {
    this.getUserData();
    if (this.$route.name === "user-email-confirm") {
      this.axios
        .post("/api/auth/user-email-confirm/token", {
          token: this.$route.params.token,
        })
        .then((res) => {
          if (res.message == "Sms code sent for confirmation") {
            this.$router.push({ name: "confirm-phone" });
          }
        });
    }
  },
};
