//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
import StyledLink from "../common/StyledLink.vue";
export default {
  components: { StyledLink, ButtonsTray },
  name: "CardsLanding",
  computed: {
    blockClass() {
      switch (+this.propsData.title_column_select) {
        case 1:
          return "cell1";
        case 2:
          return "cell2";
        case 3:
          return "cell3";
        case 4:
          return "cell4";
        default:
          return "cell1";
      }
    },
    linkClass() {
      switch (this.blockClass) {
        case "cell1":
          return "stroke";
        case "cell2":
          return "stroke";
        case "cell3":
          return "simple";
        case "cell4":
          return "underline";
        default:
          return "stroke";
      }
    },
  },
};
