//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email } from "vuelidate/lib/validators";
export default {
  name: "regiserPage",
  data() {
    return {
      showError: false,
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
      phone: null,
      errors: null,
      success: false,
    };
  },
  validations: {
    name: { required },
    email: { email, required },
    phone: { required },
    password: { required },
    confirmPassword: { required },
  },
  computed: {
    sameAs() {
      return this.password === this.confirmPassword;
    },
    uniqEmail() {
      return this.errors?.params.email[0] == "validation.unique" ? true : null;
    },
  },
  methods: {
    register() {
      this.showError = true;
      if (!this.$v.$invalid && this.showError && this.sameAs) {
        this.axios
          .post("/api/auth/register", {
            name: this.name,
            email: this.email,
            phone: this.phone,
            password: this.password,
            password_confirmation: this.confirmPassword,
          })
          .then(() => {
            this.success = true;
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 3000);
          })
          .catch((res) => {
            console.log(res.response.data.data.errors);
            this.errors = res.response.data.data.errors;
          });
      }
    },
  },
};
