//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "accordionItem",
	props: {
		num: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			active: false,
			bd: null,
			canToggle: true,
		};
	},
	methods: {
		slideUp(target, duration = 500) {
			target.style.transitionProperty = "height, margin, padding";
			target.style.transitionDuration = duration + "ms";
			target.style.boxSizing = "border-box";
			target.style.height = target.offsetHeight + "px";
			target.offsetHeight;
			target.style.overflow = "hidden";
			target.style.height = 0;
			target.style.paddingTop = 0;
			target.style.paddingBottom = 0;
			target.style.marginTop = 0;
			target.style.marginBottom = 0;
			window.setTimeout(() => {
				target.style.display = "none";
				target.style.removeProperty("height");
				target.style.removeProperty("padding-top");
				target.style.removeProperty("padding-bottom");
				target.style.removeProperty("margin-top");
				target.style.removeProperty("margin-bottom");
				target.style.removeProperty("overflow");
				target.style.removeProperty("transition-duration");
				target.style.removeProperty("transition-property");
				//alert("!");
			}, duration);
		},
		/* SLIDE DOWN */
		slideDown(target, duration = 500) {
			target.style.removeProperty("display");
			let display = window.getComputedStyle(target).display;
			if (display === "none") display = "block";
			target.style.display = display;
			let height = target.offsetHeight;
			target.style.overflow = "hidden";
			target.style.height = 0;
			target.style.paddingTop = 0;
			target.style.paddingBottom = 0;
			target.style.marginTop = 0;
			target.style.marginBottom = 0;
			target.offsetHeight;
			target.style.boxSizing = "border-box";
			target.style.transitionProperty = "height, margin, padding";
			target.style.transitionDuration = duration + "ms";
			target.style.height = height + "px";
			target.style.removeProperty("padding-top");
			target.style.removeProperty("padding-bottom");
			target.style.removeProperty("margin-top");
			target.style.removeProperty("margin-bottom");
			window.setTimeout(() => {
				target.style.removeProperty("height");
				target.style.removeProperty("overflow");
				target.style.removeProperty("transition-duration");
				target.style.removeProperty("transition-property");
			}, duration);
		},
		slideToggle(target, duration = 500) {
			if (this.active) {
				return this.slideDown(target, duration);
			} else {
				return this.slideUp(target, duration);
			}
		},
		onToggle() {
			if (this.canToggle) {
				this.canToggle = false;
				this.active = !this.active;
				this.slideToggle(this.$refs.bd);
				setTimeout(() => {
					this.canToggle = true;
				}, 500);
			}
		},
	},
};
