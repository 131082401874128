//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
import Slider from "../common/Slider.vue";
export default {
  components: { ButtonsTray, Slider },
  name: "QuoteSliderSection",
  props: {},
  data() {
    return {
      flickityOptions: {
        cellAlign: "center",
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
        // wrapAround: true,
      },
    };
  },
  computed: {},
  methods: {
    next() {
      this.$refs.flickity.$refs.flickity.next(true, false);
    },
    prew() {
      this.$refs.flickity.$refs.flickity.previous(true, false);
    },
    select(index) {
      this.$refs.flickity.$refs.flickity.select(index, true, false);
    },
    imgLoaded() {
      this.$refs.flickity.$refs.flickity.resize();
    },
  },
};
