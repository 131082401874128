import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
    // locale: process.env.VUE_APP_I18N_LOCALE || 'uk',
    locale: "uk",
    // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    fallbackLocale: "en",
    messages: {
        uk: {
            fieldErrors: {
                email: 'Введiть email',
                required: "Обов'язкове поле",
                minLength: "Мінімальна кількість символів: {length}",
                maxLength: "Максимальна кількість символів: {length}",
                selectDate: "Вкажіть дату",
                selectRadio: "Оберіть варіант",
                selectTime: "Вкажіть час",
            },
            back: "Назад",
            page: {
                text404: "Помилка",
                text404_2: "Ця сторінка знаходиться на стадії розробки, спробуйте завітати на цю сторінку пізніше!",
            },
            footer: {
                subscribeSuccess: 'Підписку успішно оформлено! &#128522;'
            },
        },
        en: {
            fieldErrors: {
                email: 'Enter Email',
                required: "Required field",
                minLength: "Minimum number of characters: {length}",
                maxLength: "Maximum number of characters: {length}",
                selectDate: "Please enter a date",
                selectRadio: "Choose an option",
                selectTime: "Specify the time",
            },
            back: "Back",
            page: {
                text404: "Error",
                text404_2: "This side is at the stage of development, try to turn on the first side!",
            },
            footer: {
                subscribeSuccess: 'The subscription has been successfully completed! &#128522;'
            },
        },
    },
});
