//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
// import StyledLink from "../common/StyledLink.vue";
export default {
  components: { ButtonsTray },
  name: "simpleText",
  props: {},
  computed: {
    textSize() {
      return this.propsData.font_size;
    },
  },
};
