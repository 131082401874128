//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapGetters } from "vuex";
import GoogleMapsApiLoader from "google-maps-api-loader";
export default {
  name: "contacts",
  data() {
    return {
      // current: 1,

      google: null,
      map: null,
      markers: [],
      mapConfig: {
        zoom: 7,
        center: { lat: 49.224154, lng: 31.505217 },
      },
    };
  },
  computed: {
    // ...mapGetters(["options"]),
    markersData() {
      const list = [];
      this.propsData.contacts.forEach((contact) => {
        contact.maps_marks.forEach((marker) => {
          list.push(marker);
        });
      });
      return list;
    },
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.propsData.api_key,
      language: this.$i18n.locale,
    });
    this.google = googleMapApi;
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.mainMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      this.markersData.forEach((marker) => {
        const markerObj = new this.google.maps.Marker({
          position: {
            lat: +marker.lat,
            lng: +marker.lng,
          },
          map: this.map,
        });
        this.markers.push(markerObj);
      });
      // this.markersData.forEach((item) => {
      //   let contentString = `<h2>${item.translate.title}</h2>`;
      //   contentString += `<p><b>${this.$t("map.address")}: </b>${
      //     item.translate.address
      //   }</p>`;
      //   contentString += `<p><b>${this.$t("map.schedule")}: </b>${
      //     item.translate.schedule
      //   }</p>`;
      //   contentString += `<p><b>${this.$t("map.email")}: </b>${
      //     item.translate.email
      //   }</p>`;
      //   contentString += `<p><b>${this.$t("map.phone")}: </b>${
      //     item.translate.phone
      //   }</p>`;

      //   const window = new this.google.maps.InfoWindow({
      //     content: contentString,
      //   });
      //   this.windows.push(window);
      // });
      // this.markers.forEach((item, index) => {
      //   item.addListener("click", () => {
      //     this.windows.forEach((wind, winInd) => {
      //       if (index == winInd)
      //         wind.open({
      //           anchor: item,
      //           map: this.map,
      //           shouldFocus: true,
      //         });
      //       else wind.close();
      //     });
      //   });
      // });
    },
  },
};
