//
//
//
//
//

import BlockForm from '../formWidget/BlockForm.vue';
import ModalForm from '../formWidget/ModalForm.vue';
export default {
  components: { ModalForm, BlockForm },
  name: "FormAdapter",
  computed: {
    blockType() {
      if (this.propsData && this.propsData.length) {
        return "block";
      } else {
        return "modal";
      }
    },
  },
};
