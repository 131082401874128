//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import AppLink from "../common/AppLink.vue";
import BurgerSubmenu from "./BurgerSubmenu.vue";
import BurgerMenuFirstLvl from "./BurgerMenuFirstLvl.vue";
export default {
  components: { AppLink, BurgerSubmenu, BurgerMenuFirstLvl },
  data() {
    return {
      secondLvl: null,
    };
  },
  computed: {
    ...mapGetters(["getSocialLinks", "getLogo"]),
  },
  methods: {
    setChildren(data) {
      this.secondLvl = data;
    },
    socName(s) {
      switch (s) {
        case "fb":
          return "Facebook";

        case "yt":
          return "Youtube";

        case "tw":
          return "Twitter";
      }
    },
  },
};
