//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            email: null,
            success: false
        }
    },
    methods: {
        sendLink() {
            this.axios.post('/api/auth/password-reset', {
                email: this.email
            }).then( () => {
                this.success = true
            })
        }
    },
//   created() {
    // this.axios
    //   .post("/api/auth/password-reset/token", {
    //     token: this.$route.params.token,
    //   })
    //   .then((res) => {
    //     this.changePassToken = res.data.data.token;
    //   });
//   },
};
