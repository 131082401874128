//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
export default {
  data() {
    return {
      password: null,
      token: null,
      changePassToken: null,
      success: null,
      errors: null
    };
  },
  methods: {
    ...mapActions(["setAccessToken"]),
    changePass() {
      this.axios
        .post("/api/auth/password-change", {
          token: this.changePassToken,
          password: this.password,
        })
        .then((res) => {
            this.setAccessToken(res.data.data.access_token)
          localStorage.setItem("accessToken", res.data.data.access_token);
          this.success = true
          setTimeout( () => {
            this.$router.push('/account')
          }, 1000)
        }).catch((res) => {
            this.errors = res.response.data.data.errors
        })
    },
  },

  created() {
    this.axios
      .post("/api/auth/password-reset/token", {
        token: this.$route.params.token,
      })
      .then((res) => {
        this.changePassToken = res.data.data.token;
      });
  },
};
