//
//
//
//
//
//
//
//

export default {
  created() {
    this.axios
      .post("/api/auth/user-email-confirm/token", {
        token: this.$route.params.token,
      })
      .then((res) => {
        if (res.message == "Sms code sent for confirmation") {
          this.$router.push({ name: "confirm-phone" });
        } else {
            this.$router.push({ name: "login" });
        }
      });
  },
};
