//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import ButtonsTray from "../common/ButtonsTray.vue";
import StyledLink from "../common/StyledLink.vue";
export default {
  name: "TextNextColBtn",
  components: {
    StyledLink,
    // ButtonsTray,
  },
  computed: {
    textSize() {
      return this.propsData.font_size;
    },
  },
};
